import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      component: Home,
      showInMainNav: false
    },
    {
      path: '/home',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      component: Home,
      showInMainNav: true
    },
    {
      path: '/login',
      name: 'Login',
      roles: [],
      component: Login,
      showInMainNav: false
    },
    {
      path: '/profile',
      name: 'Profil',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      // lazy-loaded
      component: () => import('./views/Profile.vue'),
      showInMainNav: false
    },
    {
      path: '/tagged',
      name: 'Tags',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/TaggedEntities.vue'),
      showInMainNav: false
    },
    {
      path: '/search',
      name: 'Suche',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Search.vue'),
      showInMainNav: false
    },
    {
      path: '/dispo',
      filteredUrl: '',
      name: 'Dispo',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      // lazy-loaded
      component: () => import('./views/Dispo.vue'),
      showInMainNav: true
    },
    {
      path: '/events',
      filteredUrl: '/events?vueBootstrapTableConfig=%7B%22displayColsVisible%22%3A%5B%22flagged%22%2C%22lexofficeRef%22%2C%22status%22%2C%22customer%22%2C%22inventoryItems%22%2C%22deliveryAdressZipCode%22%2C%22deliveryAdressCity%22%2C%22eventDateTimeStart%22%2C%22contact1name%22%2C%22contact1phone%22%2C%22deliveryDetailsElevatorStairs%22%2C%22plannedDeliveryDateTime%22%2C%22title%22%2C%22followUpDateTime%22%2C%22tags%22%2C%22createdBy%22%5D%2C%22columnFilterValues%22%3A%7B%7D%2C%22filterKey%22%3A%22%22%7D' ,
      name: 'Events',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Events.vue'),
      showInMainNav: true
    },
    {
      path: '/events/:entityId',
      name: 'EventDetails',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      // lazy-loaded
      component: () => import('./views/EventDetails.vue'),
      showInMainNav: false
    },
    {
      path: '/meetings',
      filteredUrl: '/meetings?vueBootstrapTableConfig=%7B%22displayColsVisible%22%3A%5B%22status%22%2C%22meetingPlannedStartDateTime%22%2C%22title%22%2C%22customer%22%2C%22contactDetailsPhone%22%2C%22locationType%22%2C%22location%22%2C%22meetingType%22%2C%22followUpDateTime%22%2C%22pariticipantsOfficeUsers%22%2C%22pariticipantsExternalUsers%22%2C%22tags%22%2C%22createdBy%22%5D%2C%22columnFilterValues%22%3A%7B%7D%2C%22filterKey%22%3A%22%22%7D',
      name: 'Termine',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Meetings.vue'),
      showInMainNav: true
    },
    {
      path: '/meetings/:entityId',
      name: 'MeetingDetails',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP', 'ROLE_EXTERNAL'],
      // lazy-loaded
      component: () => import('./views/MeetingDetails.vue'),
      showInMainNav: false
    },
    {
      path: '/customers/:customerId',
      name: 'KundenDetails',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/CustomerDetails.vue'),
      showInMainNav: false
    },
    {
      path: '/customers',
      filteredUrl: '',
      name: 'Kunden',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Customers.vue'),
      showInMainNav: true
    },
    {
      path: '/inventory/:inventoryItemId',
      name: 'InventarDetails',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/InventoryItemDetails.vue'),
      showInMainNav: false
    },
    {
      path: '/inventory',
      filteredUrl: '/inventory?vueBootstrapTableConfig=%7B%22displayColsVisible%22%3A%5B%22inventoryID%22%2C%22lastInventoryCheckDate%22%2C%22displayName%22%2C%22deviceType%22%2C%22deviceCategories%22%2C%22status%22%2C%22location%22%2C%22brandingPossible%22%2C%22partialModule%22%2C%22currentlyBranded%22%2C%22tags%22%5D%2C%22columnFilterValues%22%3A%7B%7D%2C%22filterKey%22%3A%22%22%7D',
      name: 'Inventar',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/InventoryItems.vue'),
      showInMainNav: true
    },
    {
      path: '/articles',
      filteredUrl: '/articles?vueBootstrapTableConfig=%7B"displayColsVisible"%3A%5B"articleNumber"%2C"articleLanguageVersion"%2C"articleName"%2C"articleBasePrice"%2C"inventoryType"%5D%2C"columnFilterValues"%3A%7B%7D%7D',
      name: 'Artikelstammdaten',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/ArticleMasterDataItems.vue'),
      showInMainNav: false
    },
    {
      path: '/locations',
      filteredUrl: '/locations?vueBootstrapTableConfig=%7B"displayColsVisible"%3A%5B"displayName"%2C"websiteURL"%2C"locationStreetAndHousenumber"%2C"locationZipCode"%2C"locationCity"%2C"locationCountryCode"%5D%2C"columnFilterValues"%3A%7B%7D%2C"filterKey"%3A""%7D',
      name: 'Locations',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/Locations.vue'),
      showInMainNav: true
    },
    {
      path: '/locations/:locationItemId',
      name: 'LocationDetails',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/LocationDetails.vue'),
      showInMainNav: false
    },
    {
      path: '/user',
      filteredUrl: '/user?vueBootstrapTableConfig=%7B%22displayColsVisible%22%3A%5B%22username%22%2C%22activeState%22%2C%22firstname%22%2C%22lastname%22%2C%22email%22%2C%22mobileNumber%22%2C%22prio%22%2C%22skills%22%2C%22skillDescription%22%2C%22sprinterSkills%22%2C%22hasKey%22%2C%22locked%22%2C%22roleNames%22%2C%22tags%22%5D%2C%22columnFilterValues%22%3A%7B%22activeState%22%3A%5B%22ja%22%5D%7D%2C%22filterKey%22%3A%22%22%2C%22sortingDetails%22%3A%7B%22prio%22%3A%22DESC%22%7D%7D',
      name: 'Benutzer',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Users.vue'),
      showInMainNav: true
    },
    {
      path: '/absence',
      name: 'Abwesenheiten',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE', 'ROLE_LAGERP'],
      // lazy-loaded
      component: () => import('./views/TimeOffPlanner.vue'),
      showInMainNav: true
    },
    {
      path: '/worklogs',
      name: 'Arbeitszeiten',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/Worklogs.vue'),
      showInMainNav: true
    },
    {
      path: '/admin',
      name: 'Administraton',
      roles: ['ROLE_ADMIN'],
      // lazy-loaded
      component: () => import('./views/BoardAdmin.vue'),
      showInMainNav: true
    },
    {
      path: '/textblocks',
      name: 'Textblöcke verwalten',
      roles: ['ROLE_ADMIN', 'ROLE_OFFICE'],
      // lazy-loaded
      component: () => import('./views/TextBlockManagement.vue'),
      showInMainNav: false
    },

    
  ]
});

router.afterEach((to) => {
  document.title = "5mTool - " + to.name;
});
