<template>
  <div id="app">
    <nav
      class="navbar navbar-expand-xl navbar-dark navbar-5meter sticky-top"
      style="z-index: 950"
    >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-brand">
        5meterTool<br />
        <span
          class="session-timer"
          v-if="sessionTimeoutRemainingSeconds > 0"
          :class="{ sessionTimeoutWarning: sessionTimeoutRemainingSeconds < 60 }"
          >Session: {{ sessionTimeoutRemainingFormatted }}
          <font-awesome-icon
            class="fa-spin"
            v-if="sessionTimeoutRemainingSeconds < 60"
            icon="history"
        /></span>
      </div>

      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <template v-if="currentUser">
            <li
              v-for="route in this.$router.options.routes.filter(
                (route) => route.showInMainNav && showNavElement(route.path)
              )"
              v-if="route.name"
              v-bind:key="route.path"
              class="nav-item"
            >
              <router-link
                v-if="route.filteredUrl"
                :to="route.filteredUrl"
                class="nav-link"
                :id="'nav-' + route.name"
              >
                {{ route.name }}
              </router-link>
              <router-link
                v-else
                :to="route.path"
                class="nav-link"
                :id="'nav-' + route.name"
              >
                {{ route.name }}
              </router-link>
            </li>

            <li class="nav-item" v-if="isOfficeUserOrAdmin()" style="padding-top: 5px;">
              <b-input-group>
                <b-form-input
                  v-model="searchTermn"
                  size="sm"
                  style="max-width: 200px"
                  @keyup.enter="$router.push('/search?query=' + searchTermn)"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="success"
                    @click="$router.push('/search?query=' + searchTermn)"
                  >
                    <font-awesome-icon icon="search" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </li>
            <li class="nav-item">
              <b-dropdown size="sm" 
                id="extraFunctionsDropdown"
                class="m-md-1"
                variant="primary"
                right
              >
                <template #button-content>
                  <font-awesome-icon icon="cogs" />
                </template>

                <b-dropdown-item to="/profile" variant="primary">
                  <font-awesome-icon icon="user" />
                  {{ currentUser.username }}
                </b-dropdown-item>
                <b-dropdown-item to="/textblocks" variant="primary">
                  <font-awesome-icon icon="newspaper" />
                  Textblöcke verwalten
                </b-dropdown-item>
                <b-dropdown-item>
                  <a href @click.prevent="logOut">
                  <font-awesome-icon icon="door-open" />
                   Log Out
                   </a>
                </b-dropdown-item>
              </b-dropdown>
            </li>
          </template>

          <template v-else>
            <li class="nav-item">
              <router-link to="/login" class="nav-link">
                <font-awesome-icon icon="sign-in-alt" />
                Login
              </router-link>
            </li>
          </template>
        </div>
      </div>
    </nav>
    <div class="container-fluid">
      <router-view v-if="configLoaded || $route.path == '/login'" />
      <template v-else>
        <div>Konfiguration wird geladen</div>
      </template>
    </div>
  </div>
</template>

<script>
import globalEventBus from "@/services/globalEventBus";
import userAccessCheckMixin from "./mixins/userAccessCheckMixin";
import socketCommunicationMixin from "./mixins/socketCommunicationMixin";
import sessionsDetailsMixin from "./mixins/sessionDetailsMixin";

export default {
  mixins: [userAccessCheckMixin, socketCommunicationMixin, sessionsDetailsMixin],
  data() {
    return {
      connected: false,
      searchTermn: "",
      configLoaded: false,
      intervalId: 0,
    };
  },
  methods: {
    validateUserSession: function () {
      if (this.$route.path.indexOf("login") == -1) {
        this.updateSessionLifetimeDetails();
        if (this.sessionTimeoutRemainingSeconds == 0) {
          window.clearInterval(this.intervalId);
          this.logOut();
        }
      }
    },
    showNavElement: function (pathToCheck) {
      var route = this.$router.options.routes.find((route) => route.path == pathToCheck);
      //check if route exists and if it is limited for specific roles and if user is logged in at all
      if (route && route.roles && route.roles.length > 0 && this.currentUser) {
        var matchingRoles = route.roles.filter((roleToCheck) => {
          return this.currentUser.roles.includes(roleToCheck);
        });
        return matchingRoles.length > 0;
      } else if (!route.roles) {
        //no specific roles required, so we show the element in any case
        return true;
      }
      return false;
    },
    logOut: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    loadConfiguration: async function () {
      this.$store.configuration = {};
      await this.$contentService.getConfiguration().then(
        (response) => {
          this.$store.configuration = response.data;
          this.configLoaded = true;
        },
        (error) => {
          this.$bvToast.toast(
            "Ein Problem ist aufgetreten beim Laden der Konfiguration. Ggf. musst du dich neu anmelden",
            {
              autoHideDelay: 1500,
              noCloseButton: true,
              variant: "error",
              solid: false,
            }
          );
          this.logOut();
          this.content =
            (error.response && error.response.data) || error.message || error.toString();
        }
      );
    },
  },
  mounted: function () {
    this.loadConfiguration();
    globalEventBus.$on("userLoginPerformed", this.loadConfiguration);
    //globalEventBus.$on("ContentUpdateEvent", this.contentUpdateEventHandler);
    //if we are logged in, connect and register to websocket topics
    if (this.currentUser) {
      this.connect();
    }
    this.intervalId = window.setInterval(this.validateUserSession, 1000);
  },
  beforeDestroy: function () {
    //globalEventBus.$off("ContentUpdateEvent", this.contentUpdateEventHandler);
    this.stopHeartBeat();
    this.disconnect();
    window.clearInterval(this.intervalId);
  },
};
</script>
<style lang="scss">
$primary: #298232;
$primary-color: #298232;

.navbar-5meter {
  background-color: $primary;
  padding: 0 1rem !important;
}

span.session-timer {
  font-size: 12px;
  padding: 0;
  position: relative;
  top: -12px;
  width: 110px;
  display: inline-block;
}

.sessionTimeoutWarning {
  color: rgb(168, 0, 0);
}

.navbar-brand {
  padding-left: 0px;
  margin-right: 0px !important;
  height: 50px;
}

@media screen and (min-width: 1350px) {
  .navbar-brand {
    background-image: url("./assets/5m-weblogo-navbar3.png");
    background-repeat: no-repeat;
    padding-left: 90px;
    height: 50px;
  }
}

@media screen and (max-width: 1180px) {
  .navbar-brand {
    background-image: url("./assets/5m-weblogo-navbar3.png");
    background-repeat: no-repeat;
    padding-left: 90px;
    height: 50px;
  }
}

// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>
